@import '../../../../../frontend/styles/tailwind.css';

.lyseblå {
  background-color: var(--design-color-surface-neutral-subtle);
}

.hvit {
  background-color: var(--design-color-background-default);
}

.helfo-blå {
  background-color: var(--design-color-surface-feedback-info-default);
}

.helfo-lilla {
  background-color: var(--helfo-color-support-01-100);
}
